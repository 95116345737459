import {objArrMapObj} from "@/untils/otherEvent"

/** 对版开放大币种列表 */
export const versionOpenBigCoinTableColumns = [
    { title: "大币种", align: "center", scopedSlots: {customRender: 'itemBigCoinSlot'}, width: 200 },
    // { title: "模型版本", align: "center", scopedSlots: {customRender: 'itemModelVersionSlot'}, width: 300 },
    // { title: "开放状态", align: "center", scopedSlots: {customRender: 'itemOpenStatusSlot'}, width: 80 },
    { title: "负责人", align: "center", scopedSlots: {customRender: 'itemBusinessUserSlot'}, width: 200 },
    { title: "基础数据", align: "center", scopedSlots: {customRender: 'itemCountSlot'}, width: 200 },
    { title: "模型/样本 迭代", align: "center", scopedSlots: {customRender: 'itemUpdateDataSlot'}, width: 250 },
    { title: "操作", align: "center", scopedSlots: {customRender: 'itemActionSlot'}, width: 120 }
]

/** 开放状态 0 开放 1 公测 -1 未开放 */
export const versionOpenBigCoinOpenStatusTypeList = [
    {name: '开放', value: 0},
    {name: '公测', value: 1},
    {name: '未开放', value: -1},
]

/** 准确率排序刷选 correctRateSort */
export const sortTypeList = [
    {name: '降序', value: 1},
    {name: '升序', value: 0},
]

/** 样式 */
export const versionOpenBigCoinOpenStatusClassType = {
    0: 'color-blue',
    1: 'color-green',
    '-1': 'color-red',
}

export const versionOpenBigCoinOpenStatusTypeMapText = (value) => {
    return objArrMapObj('value', 'name', versionOpenBigCoinOpenStatusTypeList)[value]
}