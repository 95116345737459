import instance from "@/axios"

/** 获取对版开放大币种数据 */
export const getVersionOpenBigCoinListApi = async (params) => {
    return instance({
        url: '/dq_admin/duibanSupportCoin/supportCoinList',
        method: 'GET',
        params: params
    })
}

/** 获取负责人列表 */
export const getBusinessUserListApi = async () => {
    return instance({
        url: '/dq_admin/duibanSupportCoin/groupByBusinessUser',
        method: 'GET',
    })
}
/** 获取模型版本 */
export const getModelVersionListApi = async () => {
    return instance({
        url: '/dq_admin/duibanSupportCoin/groupByModelVersion',
        method: 'GET',
    })
}

/** 编辑对版开放大币种 */
export const postEditorVersionOpenBigCoinApi = async (params) => {
    return instance({
        url: '/dq_admin/duibanSupportCoin/update',
        method: 'post',
        data: params
    })
}

/** 更新准确率数据统计 */
export const getRateDataCountApi = async (coinId) => {
    return instance({
        url: '/dq_admin/duibanSupportCoin/statictCorrectRate',
        method: 'get',
        params: {
            coinId: coinId
        }
    })
}
/** 更新版别，数据同居 */
export const getVersionDataCountApi = async (coinId) => {
    return instance({
        url: '/dq_admin/duibanSupportCoin/statistDuibanVersionCount',
        method: 'get',
        params: {
            coinId: coinId
        }
    })
}