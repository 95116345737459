var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: { width: 500, title: "编辑内容" },
      on: { ok: _vm.handleConfirm, cancel: _vm.handleCancel },
      model: {
        value: _vm.showPopup,
        callback: function($$v) {
          _vm.showPopup = $$v
        },
        expression: "showPopup"
      }
    },
    [
      _c(
        "a-form-model",
        _vm._b(
          { ref: "ruleForm", attrs: { model: _vm.params } },
          "a-form-model",
          _vm.layout,
          false
        ),
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "模型版本" } },
            [
              _c("a-input", {
                staticClass: "w-300",
                attrs: { placeholder: "版本号" },
                model: {
                  value: _vm.params.modelVersion,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "modelVersion", $$v)
                  },
                  expression: "params.modelVersion"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "训练比" } },
            [
              _c("a-input", {
                staticClass: "w-300",
                attrs: { placeholder: "训练比" },
                model: {
                  value: _vm.params.trainScale,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "trainScale", $$v)
                  },
                  expression: "params.trainScale"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "开放状态" } },
            [
              _c(
                "a-select",
                {
                  staticClass: "w-200 ml-5",
                  attrs: {
                    dropdownMatchSelectWidth: false,
                    "filter-option": _vm.untils.filterOption,
                    allowClear: "",
                    showSearch: "",
                    placeholder: "开放状态"
                  },
                  model: {
                    value: _vm.params.openStatus,
                    callback: function($$v) {
                      _vm.$set(_vm.params, "openStatus", $$v)
                    },
                    expression: "params.openStatus"
                  }
                },
                _vm._l(_vm.versionOpenBigCoinOpenStatusTypeList, function(
                  item
                ) {
                  return _c(
                    "a-select-option",
                    { key: item.value, attrs: { value: item.value } },
                    [_vm._v(_vm._s(item.name))]
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "负责人" } },
            [
              _c(
                "a-select",
                {
                  staticClass: "w-150 ml-5",
                  attrs: {
                    dropdownMatchSelectWidth: false,
                    "filter-option": _vm.untils.filterOption,
                    allowClear: "",
                    showSearch: "",
                    placeholder: "请选择负责人"
                  },
                  model: {
                    value: _vm.params.businessUser,
                    callback: function($$v) {
                      _vm.$set(_vm.params, "businessUser", $$v)
                    },
                    expression: "params.businessUser"
                  }
                },
                _vm._l(_vm.businessUserList, function(item) {
                  return _c(
                    "a-select-option",
                    { key: item, attrs: { value: item } },
                    [_vm._v(_vm._s(item))]
                  )
                }),
                1
              ),
              _c("a-input", {
                staticClass: "w-150",
                attrs: { placeholder: "选项无时可直接输入" },
                model: {
                  value: _vm.params.businessUser,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "businessUser", $$v)
                  },
                  expression: "params.businessUser"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "模型上线时间" } },
            [
              _c("a-input", {
                staticClass: "w-300",
                attrs: { placeholder: "请使用yyyy-MM-dd格式直接输入" },
                model: {
                  value: _vm.params.modelVersionUpNewTime,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "modelVersionUpNewTime", $$v)
                  },
                  expression: "params.modelVersionUpNewTime"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "排序" } },
            [
              _c("a-input", {
                staticClass: "w-300",
                attrs: { type: "number", placeholder: "请输入" },
                model: {
                  value: _vm.params.coinSort,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "coinSort", $$v)
                  },
                  expression: "params.coinSort"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }