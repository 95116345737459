<template>
  <a-modal
      :width="500"
      v-model="showPopup"
      title="编辑内容"
      @ok="handleConfirm"
      @cancel="handleCancel"
  >
    <a-form-model
        ref="ruleForm"
        :model="params"
        v-bind="layout"
    >
      <a-form-model-item label="模型版本">
        <a-input
            class="w-300"
            v-model="params.modelVersion"
            placeholder="版本号"
        />
      </a-form-model-item>
      <a-form-model-item label="训练比">
        <a-input
            class="w-300"
            v-model="params.trainScale"
            placeholder="训练比"
        />
      </a-form-model-item>
      <a-form-model-item label="开放状态">
        <a-select
            :dropdownMatchSelectWidth="false"
            :filter-option="untils.filterOption"
            allowClear
            showSearch
            class="w-200 ml-5"
            v-model="params.openStatus"
            placeholder="开放状态"
        >
          <a-select-option
              v-for="item of versionOpenBigCoinOpenStatusTypeList"
              :key="item.value"
              :value="item.value"
          >{{ item.name }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="负责人">
        <a-select
            :dropdownMatchSelectWidth="false"
            :filter-option="untils.filterOption"
            allowClear
            showSearch
            class="w-150 ml-5"
            v-model="params.businessUser"
            placeholder="请选择负责人"
        >
          <a-select-option
              v-for="item of businessUserList"
              :key="item"
              :value="item"
          >{{ item }}</a-select-option>
        </a-select>
        <a-input
            class="w-150"
            v-model="params.businessUser"
            placeholder="选项无时可直接输入"
        />
      </a-form-model-item>
      <a-form-model-item label="模型上线时间">
        <a-input
            class="w-300"
            v-model="params.modelVersionUpNewTime"
            placeholder="请使用yyyy-MM-dd格式直接输入"
        />
      </a-form-model-item>
      <a-form-model-item label="排序">
        <a-input
            class="w-300"
            type="number"
            v-model="params.coinSort"
            placeholder="请输入"
        />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>

import {isValidAndFormatDate, updateBaseObjectWithComplex} from "@/untils/otherEvent"
import {postEditorVersionOpenBigCoinApi} from "@/views/cmsPage/currencyMannage/versionOpenBigCoin/_apis"
import untils from "@/untils"
import {versionOpenBigCoinOpenStatusTypeList} from "@/views/cmsPage/currencyMannage/versionOpenBigCoin/_data"

export default {
  props: ['businessUserList'],
  data() {
    return {
      untils,
      versionOpenBigCoinOpenStatusTypeList,
      showPopup: false,
      layout: {
        labelCol: { span: 5 }, //标签占5份  共24份
        wrapperCol: { span: 19 },
      },
      params: {
        id: undefined,
        trainScale: undefined,
        businessUser: undefined,
        modelVersion: undefined,
        modelVersionUpNewTime: undefined,
        coinSort: undefined,
        openStatus: undefined
      },
      defaultData: ''
    }
  },
  methods: {
    async show(data) {
      this.params = updateBaseObjectWithComplex(this.params, data)
      this.showPopup = true
    },
    /** 确定 */
    async handleConfirm() {
      if (this.params.modelVersionUpNewTime) {
        if (!isValidAndFormatDate(this.params.modelVersionUpNewTime)) {
          return this.$message.warning('请输入正确的日期及格式：yyyy-MM-dd')
        } else {
          this.params.modelVersionUpNewTime = isValidAndFormatDate(this.params.modelVersionUpNewTime)
        }
      }
      const res = await postEditorVersionOpenBigCoinApi(this.params)
      if (res.status !== '200') return
      this.$message.info('成功')
      this.$emit('success')
      this.handleCancel()
    },
    /** 取消 */
    handleCancel() {
      this.params = {
        id: undefined,
        trainScale: undefined,
        businessUser: undefined,
        modelVersion: undefined,
        modelVersionUpNewTime: undefined,
        coinSort: undefined,
        openStatus: undefined
      }
      this.showPopup = false
    },
  }
}
</script>
<style scoped lang="scss">
</style>