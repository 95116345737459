<template>
  <div class="content">
    <div class="top">
      <a-select
          :dropdownMatchSelectWidth="false"
          @search="coinSearch"
          :filter-option="untils.filterOption"
          allowClear
          showSearch
          class="w-200 ml-5"
          v-model="params.coinId"
          placeholder="请选择大币种"
      >
        <a-select-option
            v-for="item of coinList"
            :key="item.id"
            :value="item.id"
        >{{ item.coinKindName + (item.coinCategory ? `---${item.coinCategory}` : '' ) }}</a-select-option>
      </a-select>
      <a-select
          :dropdownMatchSelectWidth="false"
          :filter-option="untils.filterOption"
          allowClear
          showSearch
          class="w-200 ml-5"
          v-model="params.customDynastyId"
          placeholder="请选择朝代"
      >
        <a-select-option
            v-for="item of dynastyList"
            :key="item.id"
            :value="item.id"
        >{{ item.dynastyName }}</a-select-option>
      </a-select>
      <a-select
          :dropdownMatchSelectWidth="false"
          :filter-option="untils.filterOption"
          allowClear
          showSearch
          class="w-200 ml-5"
          v-model="params.businessUser"
          placeholder="请选择负责人"
      >
        <a-select-option
            v-for="item of businessUserList"
            :key="item"
            :value="item"
        >{{ item }}</a-select-option>
      </a-select>
      <a-select
          :dropdownMatchSelectWidth="false"
          :filter-option="untils.filterOption"
          allowClear
          showSearch
          class="w-200 ml-5"
          v-model="params.openStatus"
          placeholder="开放状态"
      >
        <a-select-option
            v-for="item of versionOpenBigCoinOpenStatusTypeList"
            :key="item.value"
            :value="item.value"
        >{{ item.name }}</a-select-option>
      </a-select>
      <a-select
          :dropdownMatchSelectWidth="false"
          :filter-option="untils.filterOption"
          allowClear
          showSearch
          class="w-200 ml-5"
          v-model="params.modelVersion"
          placeholder="模型版本"
      >
        <a-select-option
            v-for="item of modelVersionList"
            :key="item.modelVersion"
            :value="item.modelVersion"
        >{{ item.modelVersion }}</a-select-option>
      </a-select>
      <a-select
          :dropdownMatchSelectWidth="false"
          :filter-option="untils.filterOption"
          allowClear
          showSearch
          class="w-200 ml-5"
          v-model="params.correctRateSort"
          placeholder="准确率排序刷选"
      >
        <a-select-option
            v-for="item of sortTypeList"
            :key="item.value"
            :value="item.value"
        >{{ item.name }}</a-select-option>
      </a-select>
      <a-select
          :dropdownMatchSelectWidth="false"
          :filter-option="untils.filterOption"
          allowClear
          showSearch
          class="w-200 ml-5"
          v-model="params.modelUpdateSort"
          placeholder="模型更新时间排序"
      >
        <a-select-option
            v-for="item of sortTypeList"
            :key="item.value"
            :value="item.value"
        >{{ item.name }}</a-select-option>
      </a-select>
      <a-button type="primary" @click="search">搜索</a-button>
    </div>
    <div class="table-content">
      <TableList
          :list="data"
          :businessUserList="businessUserList"
          :pagination="pagination"
          @success="getList"
          @changePage="handleChangePage"
      />
    </div>
  </div>
</template>
<script>
import TableList from "@/views/cmsPage/currencyMannage/versionOpenBigCoin/_components/TableList/index.vue"
import _bigOrSmallCoinByVersion from "@/_mixins/_bigOrSmallCoinByVersion"
import untils from "@/untils"
import {getVersionOpenBigCoinListApi} from "@/views/cmsPage/currencyMannage/versionOpenBigCoin/_apis"
import {
  sortTypeList,
  versionOpenBigCoinOpenStatusTypeList
} from "@/views/cmsPage/currencyMannage/versionOpenBigCoin/_data"
import _versionOpenBigCoinMixin
  from "@/views/cmsPage/currencyMannage/versionOpenBigCoin/_mixins/_versionOpenBigCoinMixin"

export default {
  mixins: [_bigOrSmallCoinByVersion, _versionOpenBigCoinMixin],
  components: {
    TableList
  },
  data() {
    return {
      untils,
      data: [],
      sortTypeList,
      versionOpenBigCoinOpenStatusTypeList,
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        showTotal: (total) => `共${total}条`,
      },
      params: {
        pageNum: 1,
        pageSize: 10,
        coinId: undefined,
        openStatus: undefined,
        customDynastyId: undefined,
        modelVersion: undefined,
        businessUser: undefined,
        modelUpdateSort: undefined,
        correctRateSort: undefined,
      },
    };
  },
  async mounted() {
    await this.getList()
    await this.getBusinessUserList()
    await this.getModelVersionList()
    await this.getDynastyByCustomList()
  },
  methods: {
    /** 翻页 */
    handleChangePage(page) {
      const { current } = page;
      this.$set(this.pagination, "current", current);
      this.$set(this.params, "pageNum", current);
      this.getList();
    },
    /** 搜索 */
    search() {
      this.$set(this.pagination, "current", 1);
      this.$set(this.params, "pageNum", 1);
      this.getList();
    },
    /**  获取列表数据 */
    async getList() {
      this.$loading.show()
      const res = await getVersionOpenBigCoinListApi(this.params)
      this.$loading.hide()
      if (res.status !== '200') return;
      this.data = res.data.records;
      this.pagination.total = res.data.total;
    },
  },
};
</script>
<style lang="scss" scoped>
.select-all-text {
  margin-left: 20px;
  margin-right: 20px;
}
.content {
  display: flex;
  flex-direction: column;
  .top-search {
    display: flex;
    align-items: center;
  }
  .table-content {
    flex: 1;
    overflow-y: auto;
  }
}

.top {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
  input {
    width: 250px;
    margin-right: 25px;
    margin-bottom: 20px;
  }
  button {
    margin-left: 10px;
  }
}

.pagination {
  text-align: right;
  margin-top: 10px;
}
</style>
