import {getBusinessUserListApi, getModelVersionListApi} from "@/views/cmsPage/currencyMannage/versionOpenBigCoin/_apis"

export default {
    data() {
        return{
            businessUserList: [],
            modelVersionList: []
        }
    },
    methods: {
        /** 获取负责人 */
        async getBusinessUserList() {
            const res = await getBusinessUserListApi()
            if (res.status !== '200') return;
            this.businessUserList = res.data.map(el => el.businessUser)
        },
        /** 获取模型版本 */
        async getModelVersionList() {
            const res = await getModelVersionListApi()
            if (res.status !== '200') return;
            this.modelVersionList = res.data
        }
    }
}